import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import SwipeableViews from 'react-swipeable-views';
import Pagination from './Pagination';

import {
  styles,
  SlideItem,
  PaginationContainer,
} from '../styles/swipeableslides.js';

const SwipeablePizzas = () => {
  const [index, setIndex] = React.useState(0);

  function handleChangeIndexSlides(index) {
    setIndex(index);
  }

  const data = useStaticQuery(graphql`
    query {
      caipiracicabana: file(relativePath: { eq: "caipiracicabana.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      calabria: file(relativePath: { eq: "calabria.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dueamici: file(relativePath: { eq: "due-amici.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lombodefilhote: file(relativePath: { eq: "lombo-de-filhote.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      peperoni: file(
        relativePath: { eq: "peperoni-com-chedder-e-baianinha.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndexSlides}
        style={styles.root}
        slideStyle={styles.slideContainer}
        resistance
      >
        <SlideItem>
          <Img
            fluid={data.caipiracicabana.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Caipiracicabana</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.lombodefilhote.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Lombo de Filhote</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.calabria.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Calábria</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.peperoni.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Pepperoni e Baianinha</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.dueamici.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Due Amici</p>
        </SlideItem>
      </SwipeableViews>

      <PaginationContainer>
        <Pagination
          dots={5}
          index={index}
          onChangeIndex={handleChangeIndexSlides}
        />
      </PaginationContainer>
    </>
  );
};

export default SwipeablePizzas;
