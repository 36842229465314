import styled from 'styled-components';

export const styles = {
  root: {
    padding: '0 50px',
    maxWidth: '425px',
    margin: '0 auto',
  },
  slideContainer: {
    padding: '0 10px',
  },
};

export const SlideItem = styled.div`
  max-height: 400px;
  max-width: 320px;

  .gatsby-image-wrapper {
    border-radius: 12px;
  }

  p {
    font-size: 1.1rem;
    color: #ffffff;
    font-weight: 600;
    font-style: italic;
    margin-left: 10px;
    margin-top: -35px;
    position: relative;
  }
`;

export const SlideItemPromo = styled.div`
  max-height: 550px;
  max-width: 320px;
  margin-bottom: 20px;

  .gatsby-image-wrapper {
    border-radius: 12px;
  }

  p {
    font-size: 1.1rem;
    color: #ffffff;
    font-weight: 600;
    font-style: italic;
    margin-left: 10px;
    margin-top: -35px;
    position: relative;
  }
`;

export const PaginationContainer = styled.div`
  position: relative;
  width: 100px;
  margin: 0 auto;
  height: 35px;
  margin-top: 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;
