import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import SwipeableViews from 'react-swipeable-views';
import Pagination from './Pagination';

import {
  styles,
  SlideItem,
  PaginationContainer,
} from '../styles/swipeableslides.js';

const SwipeableDoces = () => {
  const [index, setIndex] = React.useState(0);

  function handleChangeIndexSlides(index) {
    setIndex(index);
  }

  const data = useStaticQuery(graphql`
    query {
      amandita: file(relativePath: { eq: "amandita.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kinderbueno: file(relativePath: { eq: "kinderbueno.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      morango: file(relativePath: { eq: "morango.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      oreo: file(relativePath: { eq: "oreo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      especialchocolate: file(
        relativePath: { eq: "especial-chocolate-borda-vulcao.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tortuguita: file(relativePath: { eq: "tortuguita.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kitkat: file(relativePath: { eq: "kit-kat.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kitkatekinderbueno: file(
        relativePath: { eq: "kit-kat-e-kinder-bueno.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ourobrancoesonhodevalsa: file(
        relativePath: { eq: "ouro-branco-e-sonho-de-valsa.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndexSlides}
        style={styles.root}
        slideStyle={styles.slideContainer}
        resistance
      >
        <SlideItem>
          <Img
            fluid={data.especialchocolate.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Especial de Chocolate</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.amandita.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Amandita</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.kinderbueno.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Kinder Bueno</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.morango.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Morango</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.oreo.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Óreo</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.tortuguita.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Tortuguita</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.kitkat.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Kit Kat</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.kitkatekinderbueno.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Morango e Kinder</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.ourobrancoesonhodevalsa.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Ouro Branco e Sonho de Valsa</p>
        </SlideItem>
      </SwipeableViews>

      <PaginationContainer>
        <Pagination
          dots={9}
          index={index}
          onChangeIndex={handleChangeIndexSlides}
        />
      </PaginationContainer>
    </>
  );
};

export default SwipeableDoces;
