import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SwipeablePizzas from '../components/SwipeablePizzas';
import SwipeableGourmet from '../components/SwipeableGourmet';
import SwipeableDoces from '../components/SwipeableDoces';
import SwipeablePromo from '../components/SwipeablePromo';
import Sizes from '../components/Sizes';

const StyledAppBar = styled(AppBar)`
  background-color: #61070f;
  color: #fafafa;
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #fafafa;
  }

  .MuiTabs-scrollButtons {
    color: #fafafa;
  }
`;

const Menu = styled.section`
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 10px;

  .menu-title {
    margin: 25px auto;

    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: 375px) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
`;

const Item = styled.div`
  &:nth-child(odd) {
    background-color: #ee3734;

    /* .item-separator {
      border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
    } */

    .item-title {
      color: #f8e831;
    }

    .item-price,
    .item-desc {
      color: #ffffff;
    }
  }

  margin-bottom: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;

  .item-separator {
    /* border-bottom: 1px dotted rgba(0, 0, 0, 0.25); */
    display: grid;
    grid-template-columns: 1fr 160px;
    grid-column-gap: 5px;
  }

  .item-title {
    color: #ee3734;
    font-weight: bold;

    small {
      font-size: 10px;
    }
  }

  .item-title,
  .item-price {
    margin-bottom: 0;

    span {
      background-color: #6d0a08;
      padding: 2px 4px;
      color: #fece04;
      border-radius: 6px;
    }
  }

  .item-price-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    text-align: center;
  }

  .item-desc {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (min-width: 375px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const IndexPage = () => {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <Layout>
      <SEO
        title="Due Amici Cardápio"
        keywords={[
          `pizzas gourmet`,
          `pizzas tradicionais`,
          `pizzas doces`,
          `pizzas doces gourmet`,
          `calzones`,
          `sucos`,
          `bebidas`,
          `disk entregas`,
          `pizzaria piracicaba`,
        ]}
      />
      <main>
        <StyledAppBar
          position="sticky"
          color="default"
          square={true}
          elevation={0}
        >
          <StyledTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="Pizzas Tradicionais" />
            <Tab label="Pizzas Gourmet" />
            <Tab label="Pizzas Doces" />
            <Tab label="Promoções" />
            <Tab label="Bordas" />
            <Tab label="Bebidas" />
          </StyledTabs>
        </StyledAppBar>

        <SwipeableViews
          index={value}
          onChangeIndex={handleChangeIndex}
          animateHeight
        >
          <div>
            <Menu>
              <h1 className="menu-title">Pizzas Tradicionais</h1>

              <Sizes />

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Alho</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">37,90</h4>

                    <h4 className="item-price">56,90</h4>

                    <h4 className="item-price">71,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, alho e tomate.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Alface</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">37,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, calabresa moída, bacon, alface e tomate cereja.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">À Moda</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">62,90</h4>

                    <h4 className="item-price">73,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, frango, milho, bacon, palmito e catupiry.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">À Moda da Casa</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">45,90</h4>

                    <h4 className="item-price">69,90</h4>

                    <h4 className="item-price">79,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, carne seca desfiada, brócolis temperado e catupiry.
                </p>
              </Item>
            </Menu>

            <SwipeablePizzas />

            <Menu>
              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Atum</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">43,90</h4>

                    <h4 className="item-price">61,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, atum e catupiri.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Atum Especial</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">45,90</h4>

                    <h4 className="item-price">63,90</h4>

                    <h4 className="item-price">72,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, atum, cream cheese, ervilha e parmesão.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Americana</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">65,90</h4>

                    <h4 className="item-price">74,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, presunto, ervilha, milho, palmito, bacon e ovo.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Bacon</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">39,90</h4>

                    <h4 className="item-price">56,90</h4>

                    <h4 className="item-price">64,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, tomate e bacon.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Baianinha</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">39,90</h4>

                    <h4 className="item-price">56,90</h4>

                    <h4 className="item-price">64,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, calabresa moída apimentada e vinagrete.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Baianinha Especial</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">42,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, calabresa moída apimentada, vinagrete, cheddar e
                  bacon.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Brasileira</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">69,90</h4>

                    <h4 className="item-price">79,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, palmito, tomate, picanha e catupiry.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Brasileira Especial</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">73,90</h4>

                    <h4 className="item-price">83,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, brócolis, palmito, champignon, picanha e catupiry.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Brócolis</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, brócolis, bacon, catupiry e alho frito.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Brócolis com Tomate Seco</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">43,90</h4>

                    <h4 className="item-price">62,90</h4>

                    <h4 className="item-price">71,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, brócolis e tomate seco.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Caipiracicabana</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">44,90</h4>

                    <h4 className="item-price">62,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Creme de milho, mussarela, parmesão, bacon, azeitonas,
                  finalizada com cheiro verde e milho cozido.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Calacatú</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, calabresa e catupiry.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Calabresa</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">37,90</h4>

                    <h4 className="item-price">54,90</h4>

                    <h4 className="item-price">64,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, calabresa e cebola.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Calábria</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">47,90</h4>

                    <h4 className="item-price">64,90</h4>

                    <h4 className="item-price">75,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, lombo canadense, calabresa moída, ovo, bacon e
                  cebola.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Carne Seca</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">46,90</h4>

                    <h4 className="item-price">64,90</h4>

                    <h4 className="item-price">74,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, carne seca desfiada, tomate e catupiry.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Catupiry</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, tomate e catupiry.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Escarola</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">36,90</h4>

                    <h4 className="item-price">56,90</h4>

                    <h4 className="item-price">62,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, escarola, alho frito e bacon.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Espanhola</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">46,90</h4>

                    <h4 className="item-price">66,90</h4>

                    <h4 className="item-price">76,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, calabresa moída, ovo, palmito, tomate, bacon e
                  cheddar.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Especiale</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">67,90</h4>

                    <h4 className="item-price">77,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, pepperoni, champignon, palmito e ervilha.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Franbacon</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">45,90</h4>

                    <h4 className="item-price">65,90</h4>

                    <h4 className="item-price">75,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, frango, cream cheese e bacon caramelizado.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Frango</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">37,90</h4>

                    <h4 className="item-price">57,90</h4>

                    <h4 className="item-price">67,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, frango e catupiry.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Frango Especial</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">38,90</h4>

                    <h4 className="item-price">58,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, frango, catupiry e batata palha.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Frangone</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">38,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, frango, milho e cheddar.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Halley Especial</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">45,90</h4>

                    <h4 className="item-price">66,90</h4>

                    <h4 className="item-price">76,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, lombo canadense, tomate, champignon e catupiry.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Italiana</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, milho, bacon, champignon, catupiry e manjericão.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Lombo</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">37,90</h4>

                    <h4 className="item-price">56,90</h4>

                    <h4 className="item-price">66,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, lombo canadense e catupiry.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Lombo Especial</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">39,90</h4>

                    <h4 className="item-price">61,90</h4>

                    <h4 className="item-price">71,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, lombo canadense, milho, tomate, cebola e cheddar.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Lombo de Filhote</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">49,90</h4>

                    <h4 className="item-price">69,90</h4>

                    <h4 className="item-price">79,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Molho, queijo, filhote assado na brasa e desfiado, ervilhas,
                  alcaparras, finalizada com catupiry ou cheddar.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Marguerita</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">71,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, tomate, parmesão e manjericão.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Mussarela</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">39,90</h4>

                    <h4 className="item-price">56,90</h4>

                    <h4 className="item-price">68,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela e tomate.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Milho</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">37,90</h4>

                    <h4 className="item-price">55,90</h4>

                    <h4 className="item-price">65,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, milho e catupiry.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Mexicana</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">44,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, calabresa moída, tomate, pimentão, bacon e alho
                  frito.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Nordestina</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">46,90</h4>

                    <h4 className="item-price">69,90</h4>

                    <h4 className="item-price">79,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, carne seca, tomate, cebola, pimentão e catupiry.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Palmito</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">43,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, palmito e catupiry.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Palmito Especial</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">45,90</h4>

                    <h4 className="item-price">62,90</h4>

                    <h4 className="item-price">74,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, palmito, presunto, tomate, cebola e champignon.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Peito de Peru</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">67,90</h4>

                    <h4 className="item-price">77,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, peito de peru, champignon e catupiry.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Peruana</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">49,90</h4>

                    <h4 className="item-price">69,90</h4>

                    <h4 className="item-price">79,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, cream cheese, batata palha, peito de peru e
                  catupiry.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Pepperoni</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">49,90</h4>

                    <h4 className="item-price">64,90</h4>

                    <h4 className="item-price">75,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, pepperoni e catupiry.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Pepperoni com Cheddar</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">69,90</h4>

                    <h4 className="item-price">79,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, pepperoni, cheddar e parmesão.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Pepperoni Especial</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">53,90</h4>

                    <h4 className="item-price">72,90</h4>

                    <h4 className="item-price">82,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, pepperoni, champignon, bacon e cebola.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Portuguesa</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">40,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">68,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, presunto, ovo, ervilha, palmito e cebola.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Presunto</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">37,90</h4>

                    <h4 className="item-price">55,90</h4>

                    <h4 className="item-price">65,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, presunto e tomate.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Rúcula</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, rúcula, tomate seco e parmesão.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Strogonoff de Carne</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">54,90</h4>

                    <h4 className="item-price">75,90</h4>

                    <h4 className="item-price">85,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, strogonoff de filé mignon, champignon e batata
                  palha.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Strogonoff de Frango</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">49,90</h4>

                    <h4 className="item-price">69,90</h4>

                    <h4 className="item-price">79,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, strogonoff de frango, champignon e batata palha.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Vegetariana</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">44,90</h4>

                    <h4 className="item-price">61,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, brócolis, milho, ervilha, palmito, pimentão e
                  cebola.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">3 Queijos</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">39,90</h4>

                    <h4 className="item-price">56,90</h4>

                    <h4 className="item-price">66,90</h4>
                  </div>
                </div>

                <p className="item-desc">Mussarela, catupiry e provolone.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">4 Queijos</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">44,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, catupiry, provolone e parmesão.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">5 Queijos</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">46,90</h4>

                    <h4 className="item-price">64,90</h4>

                    <h4 className="item-price">75,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, catupiry, provolone, parmesão e gorgonzola.
                </p>
              </Item>
            </Menu>
          </div>

          <div>
            <Menu>
              <div className="menu-container">
                <h1 className="menu-title">Pizzas Gourmet</h1>

                <Sizes />

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Aliche</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">55,90</h4>

                      <h4 className="item-price">69,90</h4>

                      <h4 className="item-price">79,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Muçarela, aliche, tomate e parmesão.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Due Amici</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">54,90</h4>

                      <h4 className="item-price">71,90</h4>

                      <h4 className="item-price">81,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, cream cheese, peito de peru, alho-poró, tomate
                    cereja e parmesão.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Di Parma</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">56,90</h4>

                      <h4 className="item-price">77,90</h4>

                      <h4 className="item-price">85,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, manjericão, tomate e presunto parma.
                  </p>
                </Item>
              </div>
            </Menu>

            <SwipeableGourmet />

            <Menu>
              <div className="menu-container">
                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Di Napoli</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">49,90</h4>

                      <h4 className="item-price">62,90</h4>

                      <h4 className="item-price">72,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, shitake e shimeji com shoyu e azeite.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Filé Mignon</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">58,90</h4>

                      <h4 className="item-price">79,90</h4>

                      <h4 className="item-price">89,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, isca de filé mignon com shoyu e catupiry.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Flanboyant</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">58,90</h4>

                      <h4 className="item-price">79,90</h4>

                      <h4 className="item-price">89,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, filé mignon com shoyu banana e parmesão.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Costela</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">55,90</h4>

                      <h4 className="item-price">71,90</h4>

                      <h4 className="item-price">81,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, costela, catupiry, vinagrete e parmesão.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Costela com Cheddar</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">56,90</h4>

                      <h4 className="item-price">72,90</h4>

                      <h4 className="item-price">82,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, costela, tomate, cheddar e parmesão.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Zuchine</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">42,90</h4>

                      <h4 className="item-price">54,90</h4>

                      <h4 className="item-price">64,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, abobrinha temperada, tomate seco e parmesão.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Camarão Especial</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">55,90</h4>

                      <h4 className="item-price">77,90</h4>

                      <h4 className="item-price">88,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, camarão temperado, catupiry, ervilha, tomate e
                    parmesão.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">À Moda Pizzaiolo</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">47,90</h4>

                      <h4 className="item-price">65,90</h4>

                      <h4 className="item-price">75,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, linguiça caipira, tomate cereja, cebola, parmesão
                    e manjericão.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Super Bacon</h4>

                    <div className="item-price-wrapper ">
                      <h4 className="item-price">51,90</h4>

                      <h4 className="item-price">69,90</h4>

                      <h4 className="item-price">79,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Mussarela, alho poró, catupiry e bacon caramelizado.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Estufada</h4>

                    <div className="item-price-wrapper ">
                      <p className="item-price"></p>

                      <p className="item-price"></p>

                      <h4 className="item-price">112,90</h4>
                    </div>
                  </div>

                  <p className="item-desc ">
                    1 kilo de mussarela, mais qualquer pizza do cardápio até 2
                    sabores.
                  </p>
                </Item>

                <Item>
                  <div className="item-separator ">
                    <h4 className="item-title ">Calzones</h4>

                    <div className="item-price-wrapper ">
                      <p className="item-price"></p>

                      <h4 className="item-price">24,90</h4>

                      <p className="item-price"></p>
                    </div>
                  </div>

                  <p className="item-desc ">
                    Qualquer sabor do cardápio. Disponível apenas para 8
                    pedaços. R$ 24,90 + valor do sabor escolhido.
                  </p>
                </Item>
              </div>
            </Menu>
          </div>

          <div>
            <Menu>
              <h1 className="menu-title">Pizzas Doces</h1>

              <Sizes />

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Especial de Chocolate</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">49,90</h4>

                    <h4 className="item-price">61,90</h4>

                    <h4 className="item-price">74,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Monte com até 6 ingredientes do cardápio doce.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Kinder Bueno</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">Chocolate ao leite e Kinder Bueno.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Kinder com Ninho</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite, leite Ninho e Kinder Bueno.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Kinder Ovo</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite e Kinder Ovo (acompanha os brinquedos).
                </p>
              </Item>
            </Menu>

            <SwipeableDoces />

            <Menu>
              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Nutella</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite, Nutella e avelã.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Óreo</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">44,90</h4>

                    <h4 className="item-price">55,90</h4>

                    <h4 className="item-price">67,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate branco, bolacha óreo e bis óreo.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Sonho de Valsa</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite e bombom sonho de valsa.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Ouro Branco</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate branco e bombom ouro branco.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Kit Kat</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">Chocolate ao leite e kit kat.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Amandita</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">48,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite e bombom amandita.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Tortuguita</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">42,90</h4>

                    <h4 className="item-price">56,90</h4>

                    <h4 className="item-price">68,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite, chocolate branco e tortuguita.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Prestígio</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">62,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite, coco ralado e leite condensado.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Pistache</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">49,90</h4>

                    <h4 className="item-price">59,90</h4>

                    <h4 className="item-price">69,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Creme de pistache, pistache crocante e borda de chocolate
                  branco.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Mesclada</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">62,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite e chocolate branco.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Brigadeiro</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">62,90</h4>
                  </div>
                </div>

                <p className="item-desc">Chocolate ao leite e granulado.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Morango</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">62,90</h4>
                  </div>
                </div>

                <p className="item-desc">Chocolate ao leite e morango.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Uva</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">62,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite, uvas, leite condensado e amêndoas.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Banana</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">62,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite, banana com canela e leite condensado.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Banana Nevada</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">39,90</h4>

                    <h4 className="item-price">52,90</h4>

                    <h4 className="item-price">63,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Mussarela, banana com canela, chocolate branco no forno, Leite
                  Ninho, leite condensado e cereja.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">M&amp;M’s</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">62,90</h4>
                  </div>
                </div>

                <p className="item-desc">Chocolate ao leite e m&amp;m’s.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Romeu e Julieta</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">62,90</h4>
                  </div>
                </div>

                <p className="item-desc">Muçarela e goiabada.</p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Paçoca</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">41,90</h4>

                    <h4 className="item-price">51,90</h4>

                    <h4 className="item-price">62,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite, paçoca e leite condensado.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Doce de Luxe</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">42,90</h4>

                    <h4 className="item-price">56,90</h4>

                    <h4 className="item-price">68,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Muçarela, doce de leite e cereja (opcional com doce de leite
                  ou cream cheese).
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Doce de Frutas</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">44,90</h4>

                    <h4 className="item-price">55,90</h4>

                    <h4 className="item-price">67,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Chocolate ao leite, morango, banana, kiwi, uva, cereja e leite
                  condensado.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">Cheesecake Amora</h4>

                  <div className="item-price-wrapper">
                    <h4 className="item-price">42,90</h4>

                    <h4 className="item-price">56,90</h4>

                    <h4 className="item-price">68,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Muçarela, geléia de amora, cream cheese e cereja.
                </p>
              </Item>
            </Menu>
          </div>

          <div>
            <Menu>
              <h1 className="menu-title">Promoções</h1>

              <SwipeablePromo />

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">
                    <span>Segunda</span> Pizza broto meia salgada e meia doce +
                    1 refri lata
                  </h4>

                  <div className="item-price-wrapper">
                    <p />
                    <p />
                    <h4 className="item-price">45,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Sabores participantes: À Moda, Brócolis, Franbacon, Calabresa,
                  Presunto, Frango, Portuguesa, Baianinha, Milho, Lombo, 2
                  Queijos, Prestígio, Brigadeiro, Mesclada, Banana, Morango,
                  M&amp;M’s, Paçoca, Romeu e Julieta.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">
                    <span>Terça</span> Pizza salgada de 8 pedaços + Pizza broto
                    doce
                  </h4>

                  <div className="item-price-wrapper">
                    <p />
                    <p />
                    <h4 className="item-price">75,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Sabores participantes: Calabresa, Presunto, Brócolis, Frango,
                  Portuguesa, Baianinha, Milho, Lombo, 2 Queijos, Prestígio,
                  Brigadeiro, Mesclada, Banana, Morango, M&amp;M’s, Paçoca,
                  Romeu e Julieta.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">
                    <span>Quarta</span> Pizza 8 pedaços + borda vulcão + 1 refri
                    2L
                  </h4>

                  <div className="item-price-wrapper">
                    <p />
                    <p />
                    <h4 className="item-price">79,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Sabores participantes: Franbacon, Calabresa, Presunto,
                  Brócolis, Frango, Portuguesa, Baianinha, Baianinha Especial,
                  Marguerita, Frango com Bacon, Palmito, Lombo, 2 Queijos.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">
                    <span>Quinta</span> 2 Pizzas de 8 pedaços
                  </h4>

                  <div className="item-price-wrapper">
                    <p />
                    <p />
                    <h4 className="item-price">94,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Sabores participantes: Calabresa, Presunto, Brócolis, Frango,
                  Portuguesa, Baianinha, Milho, Lombo, 2 Queijos.
                </p>
              </Item>

              <Item>
                <div className="item-separator">
                  <h4 className="item-title">
                    <span>Domingo</span> Pizza salgada 12 pedaços + Calzone
                    broto doce + 1 refri 2L
                  </h4>

                  <div className="item-price-wrapper">
                    <p />
                    <p />
                    <h4 className="item-price">89,90</h4>
                  </div>
                </div>

                <p className="item-desc">
                  Sabores participantes: Baianinha, Frango, Portuguesa, Lombo, 2
                  Queijos, Brócolis. Calzone: M&amp;M’s, Paçoca, Brigadeiro,
                  Prestígio. Refri: Guaraná ou Fanta Uva
                </p>
              </Item>
            </Menu>
          </div>

          <Menu>
            <h1 className="menu-title">Bordas Recheadas</h1>

            <Sizes bordas={true} />

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Borda Vulcão</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">12,00</h4>

                  <h4 className="item-price">17,00</h4>

                  <h4 className="item-price">19,00</h4>
                </div>
              </div>

              <p className="item-desc">
                Catupiry, cheddar, cream cheese, chocolate, chocolate branco ou
                doce de leite.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Borda Normal</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">7,00</h4>

                  <h4 className="item-price">10,00</h4>

                  <h4 className="item-price">12,00</h4>
                </div>
              </div>

              <p className="item-desc">
                Catupiry, cheddar, cream cheese, chocolate, chocolate branco ou
                doce de leite.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Borda de Frango com Catupiry</h4>

                <div className="item-price-wrapper">
                  <h4 className="item-price">13,00</h4>

                  <h4 className="item-price">18,00</h4>

                  <h4 className="item-price">20,00</h4>
                </div>
              </div>

              <p className="item-desc">
                Borda de frango com catupiry, calabrasa com catupiry ou
                mussarela.
              </p>
            </Item>
          </Menu>

          <Menu>
            <h1 className="menu-title">Bebidas</h1>

            <div className="item-obs">
              <p>- Consulte mais bebidas para Delivery</p>
            </div>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Refrigerante Lata 350ml</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">7,00</h4>
                </div>
              </div>

              <p className="item-desc">Consulte</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Refrigerante ks</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">6,50</h4>
                </div>
              </div>

              <p className="item-desc">Consulte</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Coca-Cola 600ml</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">9,00</h4>
                </div>
              </div>

              <p className="item-desc"></p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Refrigerante 2L</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">12,00</h4>
                </div>
              </div>

              <p className="item-desc">Fanta uva, laranja e guaraná.</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Coca-Cola 2L</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">17,00</h4>
                </div>
              </div>

              <p className="item-desc"></p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Suco 1L</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">14,00</h4>
                </div>
              </div>

              <p className="item-desc">
                Morango, abacaxi, abacaxi com hortelã, , limão, maracujá e
                laranja.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Suco Copo</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">8,90</h4>
                </div>
              </div>

              <p className="item-desc">
                Morango, abacaxi, abacaxi com hortelã, , limão, maracujá e
                laranja.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Suco Copo c/ Leite Condensado</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">11,90</h4>
                </div>
              </div>

              <p className="item-desc">
                Morango, abacaxi, abacaxi com hortelã, , limão, maracujá e
                laranja.
              </p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Taça de Vinho</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">12,90</h4>
                </div>
              </div>

              <p className="item-desc">Consulte</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Cerveja Long Neck</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">10,00</h4>
                </div>
              </div>

              <p className="item-desc">Consulte</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Cerveja Heineken 600ml</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">16,90</h4>
                </div>
              </div>

              <p className="item-desc">Consulte</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Cerveja Original 600ml</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">15,90</h4>
                </div>
              </div>

              <p className="item-desc">Consulte</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Cerveja Antarctica 600ml</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">13,90</h4>
                </div>
              </div>

              <p className="item-desc">Consulte</p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">H2OH!</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">7,50</h4>
                </div>
              </div>

              <p className="item-desc"></p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Água</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">5,00</h4>
                </div>
              </div>

              <p className="item-desc"></p>
            </Item>

            <Item>
              <div className="item-separator">
                <h4 className="item-title">Água com gás</h4>

                <div className="item-price-wrapper">
                  <p className="item-price"></p>

                  <p className="item-price"></p>

                  <h4 className="item-price">6,00</h4>
                </div>
              </div>

              <p className="item-desc"></p>
            </Item>
          </Menu>
        </SwipeableViews>
      </main>
    </Layout>
  );
};

export default IndexPage;
