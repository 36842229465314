import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import SwipeableViews from 'react-swipeable-views';
import Pagination from './Pagination';

import {
  styles,
  SlideItemPromo,
  PaginationContainer,
} from '../styles/swipeableslides.js';

const SwipeablePromo = () => {
  const [index, setIndex] = React.useState(0);

  function handleChangeIndexSlides(index) {
    setIndex(index);
  }

  const data = useStaticQuery(graphql`
    query {
      segunda1: file(relativePath: { eq: "segunda.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      terca: file(relativePath: { eq: "terca.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      quarta: file(relativePath: { eq: "quarta.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      quinta: file(relativePath: { eq: "quinta.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      domingo: file(relativePath: { eq: "domingo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndexSlides}
        style={styles.root}
        slideStyle={styles.slideContainer}
        resistance
      >
        <SlideItemPromo>
          <Img
            fluid={data.segunda1.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
        </SlideItemPromo>
        <SlideItemPromo>
          <Img
            fluid={data.terca.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
        </SlideItemPromo>
        <SlideItemPromo>
          <Img
            fluid={data.quarta.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
        </SlideItemPromo>
        <SlideItemPromo>
          <Img
            fluid={data.quinta.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
        </SlideItemPromo>
        <SlideItemPromo>
          <Img
            fluid={data.domingo.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
        </SlideItemPromo>
      </SwipeableViews>

      <PaginationContainer>
        <Pagination
          dots={4}
          index={index}
          onChangeIndex={handleChangeIndexSlides}
        />
      </PaginationContainer>
    </>
  );
};

export default SwipeablePromo;
