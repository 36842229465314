import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import SwipeableViews from 'react-swipeable-views';
import Pagination from './Pagination';

import {
  styles,
  SlideItem,
  PaginationContainer,
} from '../styles/swipeableslides.js';

const SwipeablePizzas = () => {
  const [index, setIndex] = React.useState(0);

  function handleChangeIndexSlides(index) {
    setIndex(index);
  }

  const data = useStaticQuery(graphql`
    query {
      dueamici: file(relativePath: { eq: "dueamici.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      costela: file(relativePath: { eq: "costela.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      calzone: file(relativePath: { eq: "calzone.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      estufada: file(relativePath: { eq: "estufada.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      superbacon: file(relativePath: { eq: "super-bacon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pizzasalgada4: file(relativePath: { eq: "pizzasalgada4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pizzasalgada5: file(relativePath: { eq: "pizzasalgada5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndexSlides}
        style={styles.root}
        slideStyle={styles.slideContainer}
        resistance
      >
        <SlideItem>
          <Img
            fluid={data.superbacon.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Super Bacon</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.costela.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Costela</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.calzone.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Calzone</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.estufada.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Estufada</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.pizzasalgada5.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Camarão</p>
        </SlideItem>
        <SlideItem>
          <Img
            fluid={data.pizzasalgada4.childImageSharp.fluid}
            objectFit="cover"
            alt="Pizza"
          />
          <p>Di Parma</p>
        </SlideItem>
      </SwipeableViews>

      <PaginationContainer>
        <Pagination
          dots={6}
          index={index}
          onChangeIndex={handleChangeIndexSlides}
        />
      </PaginationContainer>
    </>
  );
};

export default SwipeablePizzas;
