import React from 'react';
import styled from 'styled-components';

const ItemSizeWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.title === 'bebidas' ? '1fr' : '1fr 160px'};
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 25px;

  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 375px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  .item-obs {
    p {
      font-size: 0.75rem;
      font-style: italic;
      margin: 3px auto;
      line-height: 1.32857;
      font-weight: 600;
    }
  }

  .item-sizes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    text-align: center;

    small {
      font-size: 0.625rem;
    }
  }

  .sabores {
    background-color: #61070f;
    color: #fafafa;
    margin: 0 1px 15px;
    padding: 6px 0 1px 0;
    line-height: 12px;
  }

  .circle {
    position: relative;
    display: block;
    margin: 0 3px 4px 3px;
    color: #61070f;
    background-color: transparent;
    text-align: center;
  }

  .circle:after {
    display: block;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    border-radius: 50%;
    border: 1px solid #61070f;
    content: '';
  }

  .circle__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .circle__wrapper {
    display: table;
    width: 100%;
    height: 100%;
  }

  .circle__content {
    display: table-cell;
    /* padding: 1em; */
    vertical-align: middle;
  }
`;

function Sizes({ bordas }) {
  return bordas ? (
    <ItemSizeWrapper>
      <div className="item-obs"></div>

      <div className="item-sizes">
        <div>
          <div className="circle">
            <div className="circle__inner">
              <div className="circle__wrapper">
                <div className="circle__content">
                  4 <small>pdç.</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="circle">
            <div className="circle__inner">
              <div className="circle__wrapper">
                <div className="circle__content">
                  8 <small>pdç.</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="circle">
            <div className="circle__inner">
              <div className="circle__wrapper">
                <div className="circle__content">
                  12 <small>pdç.</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ItemSizeWrapper>
  ) : (
    <ItemSizeWrapper>
      <div className="item-obs">
        <p>- Pizzas meio-a-meio será cobrado o maior valor</p>
        <p>- Todas as pizzas contém molho, azeitona e orégano</p>
        <p>- Todas as pizzas não contém borda</p>
      </div>

      <div className="item-sizes">
        <div>
          <div className="circle">
            <div className="circle__inner">
              <div className="circle__wrapper">
                <div className="circle__content">
                  4 <small>pdç.</small>
                </div>
              </div>
            </div>
          </div>

          <p className="sabores">
            até 2 <small>Sabores</small>
          </p>
        </div>

        <div>
          <div className="circle">
            <div className="circle__inner">
              <div className="circle__wrapper">
                <div className="circle__content">
                  8 <small>pdç.</small>
                </div>
              </div>
            </div>
          </div>

          <p className="sabores">
            até 2 <small>Sabores</small>
          </p>
        </div>

        <div>
          <div className="circle">
            <div className="circle__inner">
              <div className="circle__wrapper">
                <div className="circle__content">
                  12 <small>pdç.</small>
                </div>
              </div>
            </div>
          </div>

          <p className="sabores">
            até 4 <small>Sabores</small>
          </p>
        </div>
      </div>
    </ItemSizeWrapper>
  );
}

export default Sizes;
